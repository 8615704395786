.university-label-red{
    background-color: red; 
    color: white;
    border-radius: 6em;   
}

.university-label-blue{
    background-color: blue;
    color: white;
    border-radius: 6em;
}

.university-label-green{
    background-color: green;
    color: white;
    border-radius: 6em;
}

.completed-button{
    background-color: gray;
    color: white;
    border-radius: 6em;
}

.fab {
    --border-radius: 50px;
    --border-width: 20px;
    /* --padding-end:20px; */
    width: 50px;
    margin-right: 10%;
}

.fab-root {
    /* margin-left: 10%; */
    margin-right: 10%;
    margin-bottom: 30%;
}