.boxes {
    width: 80%;
}

.text-size {
    font-size: large;
}

.completed-text-size {
    font-size: small;
}